<template>
  <div class="Footer">
    <slot></slot>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped>
.Footer {
  display: flex;
  align-items: center;
  padding-block: 16px;
  gap: 8px;
  flex-shrink: 0;
}
</style>
